'use client'

import './Clients.scss'
import { ReactNode } from 'react'

interface ClientsProps {
  clients: string[]
}

export function Clients(props: ClientsProps): ReactNode {
  return (
    <section className="clients">
      <h2 className="clients__heading">clients</h2>

      <div className="clients__list">
        {props.clients.map(client => (
          <div className="clients__item" key={client}>
            <p className="clients__name">{client}</p>
            <p className="clients__slash">/</p>
          </div>
        ))}
      </div>
    </section>
  )
}
