'use client'

import './Home.scss'
import { useWindowSize } from '@uidotdev/usehooks'
import Link from 'next/link'
import { ReactNode } from 'react'
import { Clients } from '@/components/Clients'
import { HomeDuplexBlock } from '@/components/HomeDuplexBlock'
import { HomeNormalBlock } from '@/components/HomeNormalBlock'
import { EagerDatoImage } from '@/components/image/EagerDatoImage'
import { LazyLoad } from '@/components/LazyLoad'
import { GalleryQuery, HomeData } from '@/data/data'

const classNameByIndex = [
  'row1col1',
  'row1col2',
  'row2col1',
  'row2col2',
  'row3spread',
  'row4col1',
  'row4col2',
  'row5col1',
  'row5col2',
  'row6spread',
]

interface HomeProps {
  data: HomeData
  clients: string[]
  children?: ReactNode
}

export function Home(props: HomeProps): ReactNode {
  const windowSize = useWindowSize()
  const innerWidth = windowSize.width || 0
  const isMobile = innerWidth <= 600

  const { galleries: allGalleries, heroImage, heading } = props.data
  let galleries: GalleryQuery[]
  let duplexGalleries: GalleryQuery[]
  let duplexIndex: number

  if (isMobile) {
    // no duplex on mobile
    galleries = allGalleries
    duplexGalleries = []
    duplexIndex = allGalleries.length
  } else {
    galleries = allGalleries.slice(0, allGalleries.length - 4)
    duplexGalleries = allGalleries.slice(-4)
    duplexIndex = galleries.length
    duplexGalleries[0]!.className = 'row7col1__douplex-container_landscape'
    duplexGalleries[1]!.className = 'row7col1__douplex-container_portrait'
    duplexGalleries[2]!.className = 'row7col2__douplex-container_portrait'
    duplexGalleries[3]!.className = 'row7col2__douplex-container_landscape'
  }

  assignLeftRight(galleries)

  return (
    <>
      <section className="intro">
        <div className="intro__inner-container">
          <div className="intro__text">
            <h1 className="intro__heading">{heading}</h1>
          </div>
          <div className="intro__image-container">
            <EagerDatoImage
              className="intro__image"
              responsiveImage={heroImage.responsiveImage}
              sizes={['(max-width: 992px) 100vw', '80vw']}
            />
          </div>
        </div>
      </section>

      <section className="grid grid_home">
        {galleries.map((gallery, i) => (
          <HomeNormalBlock
            gallery={gallery}
            galleryPath={'gallery/'}
            classNameByIndex={classNameByIndex}
            index={i}
            key={gallery.slug}
          />
        ))}

        {duplexGalleries.length ? (
          <HomeDuplexBlock
            galleries={duplexGalleries}
            galleryPath={'gallery/'}
            col1className="row7col1__douplex"
            col2className="row7col2__douplex"
            index={duplexIndex}
          />
        ) : null}
      </section>

      <LazyLoad>
        <section className="info">
          <div className="info__container">
            {props.data.selectedCases.map(selectedCase => (
              <Link
                href={`/selected-cases/${selectedCase.slug}`}
                className="info__column"
                key={selectedCase.slug}
                style={{
                  backgroundImage: `url(${selectedCase.coverImage.responsiveImage.src})`, // todo: make me responsive, please!
                }}
              >
                <div className="info__text-container">
                  <p className="info__subtitle">selected cases</p>
                  <h4 className="info__title">{selectedCase.name}</h4>
                  <p className="info__more">read more</p>
                </div>
              </Link>
            ))}
          </div>
        </section>
      </LazyLoad>

      <Clients clients={props.clients} />

      {props.children}
    </>
  )
}

function assignLeftRight(galleries: GalleryQuery[]): void {
  let left = true
  galleries.forEach(g => {
    const horizontal = g.cover?.tags?.includes('horizontal') || g.horizontal
    if (horizontal) {
      return // skip
    }

    if (left) {
      g.left = true
      left = false
    } else {
      g.right = true
      left = true
    }
  })
}
